$XS: 576px;
$SM: 576px;
$MD: 768px;
$LG: 992px;
$XL: 1200px;

@mixin my-responsive-xs() {
  @media (max-width:$XS) {
    @content;
  }
}

@mixin my-responsive-sm() {
  @media (min-width:$SM) and (max-width:$MD) {
    @content;
  }
}

@mixin my-responsive-md() {
  @media (min-width:$MD) and (max-width:$LG) {
    @content;
  }
}

@mixin my-responsive-lg() {
  @media (min-width:$LG) and (max-width:$XL) {
    @content;
  }
}

@mixin my-responsive-xl() {
  @media (min-width:$XL) {
    @content;
  }
}

@mixin my-responsive-xs-down() {
  @media (max-width:$XS) {
    @content;
  }
}

@mixin my-responsive-sm-down() {
  @media (max-width:($SM - 1px)) {
    @content;
  }
}

@mixin my-responsive-md-down() {
  @media (max-width:($MD - 1px)) {
    @content;
  }
}

@mixin my-responsive-lg-down() {
  @media (max-width:($LG - 1px)) {
    @content;
  }
}

@mixin my-responsive-xl-down() {
  @media (max-width:($XL - 1px)) {
    @content;
  }
}

@mixin my-responsive-down($WIDTH) {
  @media (max-width:($WIDTH - 1px)) {
    @content;
  }
}

@mixin my-responsive-xs-up() {
  @media (min-width:$XS) {
    @content;
  }
}

@mixin my-responsive-sm-up() {
  @media (min-width:$SM) {
    @content;
  }
}

@mixin my-responsive-md-up() {
  @media (min-width:$MD) {
    @content;
  }
}

@mixin my-responsive-lg-up() {
  @media (min-width:$LG) {
    @content;
  }
}

@mixin my-responsive-xl-up() {
  @media (min-width:$XL) {
    @content;
  }
}

@mixin my-responsive-up($WIDTH) {
  @media (min-width:($WIDTH)) {
    @content;
  }
}