@import "theme/my-responsive";

.my-background-secondary {
  --background: linear-gradient(to bottom, #fefefb 0%, #fdfdf8 100%);
}

.my-background-gray {
  --background: linear-gradient(to bottom, var(--color-light-darken-1) 0%, var(--color-light-darken-3) 100%);
}

.my-truncate-with-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.my-fill-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.my-center-xy {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.my-flex {
  display: flex;
}

.my-flex-1 {
  flex: 1;
}

hr.divider {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(55, 55, 55, 0.75), rgba(0, 0, 0, 0));
}

hr.divider2 {
  border: 0;
  height: 1px;
  margin: 3px 0px;
  background-color:  rgba(55, 55, 55, 0.75);
}

hr.divider-fit {
  border: 0;
  height: 0.55px;
  background-color: rgba(0, 0, 0, 0.12);
}

ion-title[reset-width] {
  right: 0;
  padding: initial;
  width: auto !important;
}

ion-item[transparent] {
  --background: transparent;
}

.no-ripple {
  --ripple-color: transparent;
}

ion-icon[anchor-left] {
  position: absolute;
  left: 16px;
}

ion-icon[anchor-right] {
  position: absolute;
  right: 16px;
}

ion-label.md-label {
  @include my-responsive-sm-up() {
    flex: 0 0 200px !important;
    width: 200px !important;
    min-width: 100px;
    max-width: 200px !important;
  }
}

ion-label.lg-label {
  @include my-responsive-sm-up() {
    flex: 0 0 300px !important;
    width: 300px !important;
    min-width: 100px;
    max-width: 300px !important;
  }
}

ion-label.xl-label {
  @include my-responsive-sm-up() {
    flex: 0 0 400px !important;
    width: 400px !important;
    min-width: 100px;
    max-width: 400px !important;
  }
}
