  .ion-color-open {
    --ion-color-base: var(--color-open);
    --ion-color-base-rgb: var(--color-open-rgb);
    --ion-color-contrast: var(--color-open-contrast);
    --ion-color-contrast-rgb: var(--color-open-contrast-rgb);
    --ion-color-shade: var(--color-open-shade);
    --ion-color-tint: var(--color-open-tint);
  }

  .ion-color-closed {
    --ion-color-base: var(--color-closed);
    --ion-color-base-rgb: var(--color-closed-rgb);
    --ion-color-contrast: var(--color-closed-contrast);
    --ion-color-contrast-rgb: var(--color-closed-contrast-rgb);
    --ion-color-shade: var(--color-closed-shade);
    --ion-color-tint: var(--color-closed-tint);
  }

  .ion-color-transparent {
    --ion-color-base: var(--color-transparent);
    --ion-color-base-rgb: var(--color-transparent-rgb);
    --ion-color-contrast: var(--color-transparent-contrast);
    --ion-color-contrast-rgb: var(--color-transparent-contrast-rgb);
    --ion-color-shade: var(--color-transparent-shade);
    --ion-color-tint: var(--color-transparent-tint);
  }

  .ion-color-reinsert-order-request {
    --ion-color-base: var(--color-reinsert-order-request);
    --ion-color-base-rgb: var(--color-reinsert-order-request-rgb);
    --ion-color-contrast: var(--color-reinsert-order-request-contrast);
    --ion-color-contrast-rgb: var(--color-reinsert-order-request-contrast-rgb);
    --ion-color-shade: var(--color-reinsert-order-request-shade);
    --ion-color-tint: var(--color-reinsert-order-request-tint);
  }

  .ion-color-reinsert-order {
    --ion-color-base: var(--color-reinsert-order);
    --ion-color-base-rgb: var(--color-reinsert-order-rgb);
    --ion-color-contrast: var(--color-reinsert-order-contrast);
    --ion-color-contrast-rgb: var(--color-reinsert-order-contrast-rgb);
    --ion-color-shade: var(--color-reinsert-order-shade);
    --ion-color-tint: var(--color-reinsert-order-tint);
  }

  .ion-color-facebook {
    --ion-color-base: var(--color-facebook);
    --ion-color-base-rgb: var(--color-facebook-rgb);
    --ion-color-contrast: var(--color-facebook-contrast);
    --ion-color-contrast-rgb: var(--color-facebook-contrast-rgb);
    --ion-color-shade: var(--color-facebook-shade);
    --ion-color-tint: var(--color-facebook-tint);
  }

  .ion-color-whatsapp {
    --ion-color-base: var(--color-whatsapp);
    --ion-color-base-rgb: var(--color-whatsapp-rgb);
    --ion-color-contrast: var(--color-whatsapp-contrast);
    --ion-color-contrast-rgb: var(--color-whatsapp-contrast-rgb);
    --ion-color-shade: var(--color-whatsapp-shade);
    --ion-color-tint: var(--color-whatsapp-tint);
  }

  .ion-color-dark-lighten-1 {
    --ion-color-base: var(--color-dark-lighten-1);
    --ion-color-base-rgb: var(--color-dark-lighten-1-rgb);
    --ion-color-contrast: var(--color-dark-lighten-1-contrast);
    --ion-color-contrast-rgb: var(--color-dark-lighten-1-contrast-rgb);
    --ion-color-shade: var(--color-dark-lighten-1-shade);
    --ion-color-tint: var(--color-dark-lighten-1-tint);
  }

  .ion-color-dark-lighten-2 {
    --ion-color-base: var(--color-dark-lighten-2);
    --ion-color-base-rgb: var(--color-dark-lighten-2-rgb);
    --ion-color-contrast: var(--color-dark-lighten-2-contrast);
    --ion-color-contrast-rgb: var(--color-dark-lighten-2-contrast-rgb);
    --ion-color-shade: var(--color-dark-lighten-2-shade);
    --ion-color-tint: var(--color-dark-lighten-2-tint);
  }

  .ion-color-dark-lighten-3 {
    --ion-color-base: var(--color-dark-lighten-3);
    --ion-color-base-rgb: var(--color-dark-lighten-3-rgb);
    --ion-color-contrast: var(--color-dark-lighten-3-contrast);
    --ion-color-contrast-rgb: var(--color-dark-lighten-3-contrast-rgb);
    --ion-color-shade: var(--color-dark-lighten-3-shade);
    --ion-color-tint: var(--color-dark-lighten-3-tint);
  }

  .ion-color-dark-lighten-4 {
    --ion-color-base: var(--color-dark-lighten-4);
    --ion-color-base-rgb: var(--color-dark-lighten-4-rgb);
    --ion-color-contrast: var(--color-dark-lighten-4-contrast);
    --ion-color-contrast-rgb: var(--color-dark-lighten-4-contrast-rgb);
    --ion-color-shade: var(--color-dark-lighten-4-shade);
    --ion-color-tint: var(--color-dark-lighten-4-tint);
  }

  .ion-color-dark-lighten-5 {
    --ion-color-base: var(--color-dark-lighten-5);
    --ion-color-base-rgb: var(--color-dark-lighten-5-rgb);
    --ion-color-contrast: var(--color-dark-lighten-5-contrast);
    --ion-color-contrast-rgb: var(--color-dark-lighten-5-contrast-rgb);
    --ion-color-shade: var(--color-dark-lighten-5-shade);
    --ion-color-tint: var(--color-dark-lighten-5-tint);
  }

  .ion-color-dark-lighten-6 {
    --ion-color-base: var(--color-dark-lighten-6);
    --ion-color-base-rgb: var(--color-dark-lighten-6-rgb);
    --ion-color-contrast: var(--color-dark-lighten-6-contrast);
    --ion-color-contrast-rgb: var(--color-dark-lighten-6-contrast-rgb);
    --ion-color-shade: var(--color-dark-lighten-6-shade);
    --ion-color-tint: var(--color-dark-lighten-6-tint);
  }

  .ion-color-dark-lighten-7 {
    --ion-color-base: var(--color-dark-lighten-7);
    --ion-color-base-rgb: var(--color-dark-lighten-7-rgb);
    --ion-color-contrast: var(--color-dark-lighten-7-contrast);
    --ion-color-contrast-rgb: var(--color-dark-lighten-7-contrast-rgb);
    --ion-color-shade: var(--color-dark-lighten-7-shade);
    --ion-color-tint: var(--color-dark-lighten-7-tint);
  }

  .ion-color-dark-lighten-8 {
    --ion-color-base: var(--color-dark-lighten-8);
    --ion-color-base-rgb: var(--color-dark-lighten-8-rgb);
    --ion-color-contrast: var(--color-dark-lighten-8-contrast);
    --ion-color-contrast-rgb: var(--color-dark-lighten-8-contrast-rgb);
    --ion-color-shade: var(--color-dark-lighten-8-shade);
    --ion-color-tint: var(--color-dark-lighten-8-tint);
  }

  .ion-color-gray-light {
    --ion-color-base: var(--color-gray-light);
    --ion-color-base-rgb: var(--color-gray-light-rgb);
    --ion-color-contrast: var(--color-gray-light-contrast);
    --ion-color-contrast-rgb: var(--color-gray-light-contrast-rgb);
    --ion-color-shade: var(--color-gray-light-shade);
    --ion-color-tint: var(--color-gray-light-tint);
  }

  .ion-color-background-page {
    --ion-color-base: var(--color-background-page);
    --ion-color-base-rgb: var(--color-background-page-rgb);
    --ion-color-contrast: var(--color-background-page-contrast);
    --ion-color-contrast-rgb: var(--color-background-page-contrast-rgb);
    --ion-color-shade: var(--color-background-page-shade);
    --ion-color-tint: var(--color-background-page-tint);
  }

  .ion-color-background-green {
    --ion-color-base: var(--color-background-green);
    --ion-color-base-rgb: var(--color-background-green-rgb);
    --ion-color-contrast: var(--color-background-green-contrast);
    --ion-color-contrast-rgb: var(--color-background-green-contrast-rgb);
    --ion-color-shade: var(--color-background-green-shade);
    --ion-color-tint: var(--color-background-green-tint);
  }

  .ion-color-order-pending {
    --ion-color-base: var(--color-order-pending);
    --ion-color-base-rgb: var(--color-order-pending-rgb);
    --ion-color-contrast: var(--color-order-pending-contrast);
    --ion-color-contrast-rgb: var(--color-order-pending-contrast-rgb);
    --ion-color-shade: var(--color-order-pending-shade);
    --ion-color-tint: var(--color-order-pending-tint);
  }

  .ion-color-order-accepted {
    --ion-color-base: var(--color-order-accepted);
    --ion-color-base-rgb: var(--color-order-accepted-rgb);
    --ion-color-contrast: var(--color-order-accepted-contrast);
    --ion-color-contrast-rgb: var(--color-order-accepted-contrast-rgb);
    --ion-color-shade: var(--color-order-accepted-shade);
    --ion-color-tint: var(--color-order-accepted-tint);
  }

  .ion-color-order-accepted {
    --ion-color-base: var(--color-order-accepted);
    --ion-color-base-rgb: var(--color-order-accepted-rgb);
    --ion-color-contrast: var(--color-order-accepted-contrast);
    --ion-color-contrast-rgb: var(--color-order-accepted-contrast-rgb);
    --ion-color-shade: var(--color-order-accepted-shade);
    --ion-color-tint: var(--color-order-accepted-tint);
  }

  .ion-color-order-information {
    --ion-color-base: var(--color-order-information);
    --ion-color-base-rgb: var(--color-order-information-rgb);
    --ion-color-contrast: var(--color-order-information-contrast);
    --ion-color-contrast-rgb: var(--color-order-information-contrast-rgb);
    --ion-color-shade: var(--color-order-information-shade);
    --ion-color-tint: var(--color-order-information-tint);
  }

  .ion-color-order-finished {
    --ion-color-base: var(--color-order-finished);
    --ion-color-base-rgb: var(--color-order-finished-rgb);
    --ion-color-contrast: var(--color-order-finished-contrast);
    --ion-color-contrast-rgb: var(--color-order-finished-contrast-rgb);
    --ion-color-shade: var(--color-order-finished-shade);
    --ion-color-tint: var(--color-order-finished-tint);
  }

  .ion-color-order-takeaway {
    --ion-color-base: var(--color-order-takeaway);
    --ion-color-base-rgb: var(--color-order-takeaway-rgb);
    --ion-color-contrast: var(--color-order-takeaway-contrast);
    --ion-color-contrast-rgb: var(--color-order-takeaway-contrast-rgb);
    --ion-color-shade: var(--color-order-takeaway-shade);
    --ion-color-tint: var(--color-order-takeaway-tint);
  }

  .ion-color-preorder {
    --ion-color-base: var(--color-preorder);
    --ion-color-base-rgb: var(--color-preorder-rgb);
    --ion-color-contrast: var(--color-preorder-contrast);
    --ion-color-contrast-rgb: var(--color-preorder-contrast-rgb);
    --ion-color-shade: var(--color-preorder-shade);
    --ion-color-tint: var(--color-preorder-tint);
  }

  .ion-color-prescheduledorder {
    --ion-color-base: var(--color-prescheduledorder);
    --ion-color-base-rgb: var(--color-prescheduledorder-rgb);
    --ion-color-contrast: var(--color-prescheduledorder-contrast);
    --ion-color-contrast-rgb: var(--color-prescheduledorder-contrast-rgb);
    --ion-color-shade: var(--color-prescheduledorder-shade);
    --ion-color-tint: var(--color-prescheduledorder-tint);
  }

  .ion-color-order-waitingforpay {
    --ion-color-base: var(--color-order-waitingforpay);
    --ion-color-base-rgb: var(--color-order-waitingforpay-rgb);
    --ion-color-contrast: var(--color-order-waitingforpay-contrast);
    --ion-color-contrast-rgb: var(--color-order-waitingforpay-contrast-rgb);
    --ion-color-shade: var(--color-order-waitingforpay-shade);
    --ion-color-tint: var(--color-order-waitingforpay-tint);
  }

  .ion-color-order-coordination {
    --ion-color-base: var(--color-order-coordination);
    --ion-color-base-rgb: var(--color-order-coordination-rgb);
    --ion-color-contrast: var(--color-order-coordination-contrast);
    --ion-color-contrast-rgb: var(--color-order-coordination-contrast-rgb);
    --ion-color-shade: var(--color-order-coordination-shade);
    --ion-color-tint: var(--color-order-coordination-tint);
  }

  .ion-color-request-delivery-service {
    --ion-color-base: var(--color-request-delivery-service);
    --ion-color-base-rgb: var(--color-request-delivery-service-rgb);
    --ion-color-contrast: var(--color-request-delivery-service-contrast);
    --ion-color-contrast-rgb: var(--color-request-delivery-service-contrast-rgb);
    --ion-color-shade: var(--color-request-delivery-service-shade);
    --ion-color-tint: var(--color-request-delivery-service-tint);
  }

  .ion-color-auto-rejected {
    --ion-color-base: var(--color-auto-rejected);
    --ion-color-base-rgb: var(--color-auto-rejected-rgb);
    --ion-color-contrast: var(--color-auto-rejected-contrast);
    --ion-color-contrast-rgb: var(--color-auto-rejected-contrast-rgb);
    --ion-color-shade: var(--color-auto-rejected-shade);
    --ion-color-tint: var(--color-auto-rejected-tint);
  }
  