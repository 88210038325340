@import "theme/my-responsive";

ion-popover.more-options-popover-style {
    @include my-responsive-sm-up() {
        --width: 450px;
    }

    @include my-responsive-sm-down() {
        --width: 90%;
    }
}
