@import "theme/my-responsive";

ion-popover.disabled-time-popover-style {
    @include my-responsive-sm-up() {
        --width: 350px;
    }

    @include my-responsive-sm-down() {
        --width: 90%;
    }
}
