@import "theme/my-responsive";

@mixin my-custom-modal-settings() {
    ion-modal.my-class-modal-transparent {
        --ion-background-color: transparent;

        .ion-page {
            border-radius: 0px !important;
        }
    }

    @include my-responsive-md-up() {
        ion-modal.my-class-big-modal {
            --width: 95% !important; 
            --height: 95% !important;
        }
    }

    @include my-responsive-md-up() {
        ion-modal.my-class-medium-modal {
            --width: 80% !important; 
            --height: 80% !important;
        }
    }

    @include my-responsive-md-down() {
        //Cuando estoy en un modal con margenes
        //y la pantalla mobile,
        //hago el --background transparent para
        //ocultar el ion-modal y que se vea el ion-backdrop
        ion-modal.my-class-small-modal {
            --background: transparent;
            --border-width: 5px;

            .ion-page {
                border-radius: 0px !important;
            }
        }

        //Si estoy en un modal cualquiera sin margenes
        //y la pantalla es mobile
        //ocultamos el backdrop
        ion-modal.my-class-modal.my-class-modal-up {
            --ion-backdrop-color: transparent;
        }

        ion-modal.my-class-modal.my-class-half-modal {
            --ion-background-color: transparent;
        }
    }
}

@mixin my-custom-modal($marginLeftRight: 30px, $marginTop: 25%, $marginBottom: 50%) {
    @include my-responsive-md-down() {
        :host-context(.my-class-small-modal) {
            margin-left: $marginLeftRight;
            margin-right: $marginLeftRight;
            margin-top: $marginTop;
            margin-bottom: $marginBottom;
            border-radius: 8px;
            overflow-y: hidden;
        }
    }
}

@mixin my-half-modal($minHeight: 50vh) {
    @include my-responsive-md-down() {
        position: absolute;
        bottom: 0;
        width: 100%;
        min-height: $minHeight;
    }
}

.my-button-style {
    --border-radius: 50px;
}

.my-font-medium {
    font-weight: 600;
}
