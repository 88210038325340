// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/* Para obtener el shade y tint color ver el siguiente link*/
// https://www.color-hex.com/color/ff5900

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #ef4303;
  --ion-color-primary-rgb: 239, 67, 3;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #d73c02;
  --ion-color-primary-tint: #f0551c;

  /** secondary **/
  --ion-color-secondary: #f3696b;
  --ion-color-secondary-rgb: 243, 105, 107;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #da5e60;
  --ion-color-secondary-tint: #f47879;

  /** tertiary **/
  --ion-color-tertiary: #f5f5dc;
  --ion-color-tertiary-rgb: 245, 245, 220;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #dcdcc6;
  --ion-color-tertiary-tint: #f6f6df;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #000;
  --ion-color-dark-rgb: 0, 0, 0;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --color-dark-lighten-1: #222;
  --color-dark-lighten-2: #333;
  --color-dark-lighten-3: #444;
  --color-dark-lighten-4: #555;
  --color-dark-lighten-5: #666;
  --color-dark-lighten-6: #777;
  --color-dark-lighten-7: #888;
  --color-dark-lighten-8: #ccc;

  /** gray-light **/
  --color-gray-light: #eeeeee;
  --color-gray-light-rgb: 238, 238, 238;
  --color-gray-light-contrast: #000000;
  --color-gray-light-contrast-rgb: 0, 0, 0;
  --color-gray-light-shade: #d6d6d6;
  --color-gray-light-tint: #efefef;

  /** background-green **/
  --color-background-green: #eef7ed;
  --color-background-green-rgb: 238, 247, 237;
  --color-background-green-contrast: #000000;
  --color-background-green-contrast-rgb: 0, 0, 0;
  --color-background-green-shade: #d6ded5;
  --color-background-green-tint: #eff7ee;

  /** font-green **/
  --color-font-green: #247a00;
  --color-font-green-rgb: 36, 122, 0;
  --color-font-green-contrast: #000000;
  --color-font-green-contrast-rgb: 0, 0, 0;
  --color-font-shade: #206d00;
  --color-font-tint: #398719;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --color-light-darken-1: #f9f9f9;
  --color-light-darken-2: #f4f4f4;
  --color-light-darken-3: #e9e9e9;
  --color-light-darken-4: #dedede;

  /** logged-in **/
  --color-logged-in: #818d4b;
  --color-logged-in-rgb: 129, 141, 75;

  --color-logged-out: #607d8b;
  --color-logged-out-rgb: 96, 125, 139;

  /** transparent **/
  --color-transparent: rgba(0, 0, 0, 0);
  --color-transparent-rgb: 0, 0, 0;
  --color-transparent-contrast: rgba(0, 0, 0, 0);
  --color-transparent-contrast-rgb: 0, 0, 0;
  --color-transparent-shade: rgba(0, 0, 0, 0);
  --color-transparent-tint: rgba(0, 0, 0, 0);

  /** open **/
  --color-open: #818d4b;
  --color-open-rgb: 129, 141, 75;
  --color-open-contrast: #ffffff;
  --color-open-contrast-rgb: 255, 255, 255;
  --color-open-shade: #9ea67b;
  --color-open-tint: #b7c094;

  /** closed **/
  --color-closed: #ff0000;
  --color-closed-rgb: 255, 0, 0;
  --color-closed-contrast: #ffffff;
  --color-closed-contrast-rgb: 255, 255, 255;
  --color-closed-shade: #e50000;
  --color-closed-tint: #ff1919;

  /** reinsert order request **/
  --color-reinsert-order-request: #007bff;
  --color-reinsert-order-request-rgb: 0, 123, 255;
  --color-reinsert-order-request-contrast: #ffffff;
  --color-reinsert-order-request-contrast-rgb: 255, 255, 255;
  --color-reinsert-order-request-shade: #006ee5;
  --color-reinsert-order-request-tint: #1988ff;

  --color-reinsert-order: #ff5900;
  --color-reinsert-order-rgb: 255, 89, 0;
  --color-reinsert-order-contrast: #ffffff;
  --color-reinsert-order-contrast-rgb: 255, 255, 255;
  --color-reinsert-order-shade: #e55000;
  --color-reinsert-order-tint: #ff6919;

  /** facebook **/
  --color-facebook: #3b5998;
  --color-facebook-rgb: 59, 89, 152;
  --color-facebook-contrast: #ffffff;
  --color-facebook-contrast-rgb: 255, 255, 255;
  --color-facebook-shade: #355088;
  --color-facebook-tint: #4e69a2;

  /** whatsapp **/
  --color-whatsapp: #25d366;
  --color-whatsapp-rgb: 37, 211, 102;
  --color-whatsapp-contrast: #ffffff;
  --color-whatsapp-contrast-rgb: 255, 255, 255;
  --color-whatsapp-shade: #21bd5b;
  --color-whatsapp-tint: #3ad775;

  /** order-accepted **/
  --color-order-accepted: #cfb524;
  --color-order-accepted-rgb: 207, 181, 36;
  --color-order-accepted-contrast: #ffffff;
  --color-order-accepted-contrast-rgb: 255, 255, 255;
  --color-order-accepted-shade: #baa220;
  --color-order-accepted-tint: #d3bc39;

  /** pending **/
  --color-order-pending: #ef4303;
  --color-order-pending-rgb: 239, 67, 3;
  --color-order-pending-contrast: #ffffff;
  --color-order-pending-contrast-rgb: 255, 255, 255;
  --color-order-pending-shade: #d73c02;
  --color-order-pending-tint: #f0551c;

  /** order-information **/
  --color-order-information: #3b5998;
  --color-order-information-rgb: 59, 89, 152;
  --color-order-information-contrast: #ffffff;
  --color-order-information-contrast-rgb: 255, 255, 255;
  --color-order-information-shade: #355088;
  --color-order-information-tint: #4e69a2;

  /** order-finished **/
  --color-order-finished: #818d4b;
  --color-order-finished-rgb: 129, 141, 75;
  --color-order-finished-contrast: #ffffff;
  --color-order-finished-contrast-rgb: 255, 255, 255;
  --color-order-finished-shade: #747e43;
  --color-order-finished-tint: #8d985d;

  /** order-takeaway **/
  --color-order-takeaway: #ff1493;
  --color-order-takeaway-rgb: 255, 20, 147;
  --color-order-takeaway-contrast: #ffffff;
  --color-order-takeaway-contrast-rgb: 255, 255, 255;
  --color-order-takeaway-shade: #e51284;
  --color-order-takeaway-tint: #ff2b9d;

  /** order-waitingforpay **/
  --color-order-waitingforpay: #f3696b;
  --color-order-waitingforpay-rgb: 243, 105, 107;
  --color-order-waitingforpay-contrast: #ffffff;
  --color-order-waitingforpay-contrast-rgb: 255, 255, 255;
  --color-order-waitingforpay-shade: #da5e60;
  --color-order-waitingforpay-tint: #f47879;

  /** order-coordination **/
  --color-order-coordination: #c300ff;
  --color-order-coordination-rgb: 195, 0, 255;
  --color-order-coordination-contrast: #ffffff;
  --color-order-coordination-contrast-rgb: 255, 255, 255;
  --color-order-coordination-shade: #af00e5;
  --color-order-coordination-tint: #c300ff;

  --color-request-delivery-service: #17cde9;
  --color-request-delivery-service-rgb: 66, 135, 245;
  --color-request-delivery-service-contrast: #ffffff;
  --color-request-delivery-service-contrast-rgb: 255, 255, 255;
  --color-request-delivery-service-shade: #14b8d1;
  --color-request-delivery-service-tint: #2ed2eb;

  --color-auto-rejected: #472121;
  --color-auto-rejected-rgb: 71, 33, 33;
  --color-auto-rejected-contrast: #ffffff;
  --color-auto-rejected-contrast-rgb: 255, 255, 255;
  --color-auto-rejected-shade: #3f1d1d;
  --color-auto-rejected-tint: #593737;

  /** preorder **/
  --color-preorder: #000cf0;
  --color-preorder-rgb: 0, 12, 240;
  --color-preorder-contrast: #ffffff;
  --color-preorder-contrast-rgb: 255, 255, 255;
  --color-preorder-shade: #000ad8;
  --color-preorder-tint: #1924f1;

  --color-prescheduledorder: #1bd5ff;
  --color-prescheduledorder-rgb: 27, 213, 255;
  --color-prescheduledorder-contrast: #ffffff;
  --color-prescheduledorder-contrast-rgb: 255, 255, 255;
  --color-prescheduledorder-shade: #18bfe5;
  --color-prescheduledorder-tint: #31d9ff;

  --color-order-border: #9d8a7f;
  --color-order: rgb(252, 180, 136);

  /** Fonts **/
  --ion-font-family: "Montserrat", sans-serif;
}
