ion-loading.custom-spinner-circle {
    --height: 0;

    .loading-wrapper {
        padding: 0 !important;
        background: transparent !important;
        box-shadow: initial !important;
        color: transparent !important;
    }

    .cssload-container{
        display: block;
        margin:22px auto;
        width:45px;
    }
    
    .cssload-loading{
        width: 45px;
        height: 45px;
        position: relative;
    }
    .cssload-loading>div{
        height:45px;
        opacity:0;
        position:absolute;
    }
    .cssload-loading .cssload-ani1{
        animation:cssload-loading_ani5 2s steps(1) infinite;
    }
    .cssload-loading .cssload-ani1 i{
        display:inline-block;
        width: 22px;
        height: 45px;
        background:var(--ion-color-secondary);	 
        border-radius:100% 0 0 100%/50% 0 0 50%;
    }
    .cssload-loading .cssload-ani1 i:nth-child(2),
    .cssload-loading .cssload-ani1 i:nth-child(3){
        border-radius:0 100% 100% 0/0 50% 50% 0;
        transform-origin:left center;
        animation:cssload-loading_cssload-ani1 2s infinite;
    }
    .cssload-loading .cssload-ani1 i:nth-child(2){
        background:var(--ion-color-primary);
    }
    .cssload-loading .cssload-ani1 i:nth-child(3){
        backface-visibility: hidden;
        position:absolute;
        right:0;
    }
    
    .cssload-loading .cssload-ani2{
        font-size:0;
        width:22px;
        animation:cssload-loading_ani5 2s steps(1) 0.5s infinite;
    }
    .cssload-loading .cssload-ani2 i{
        display:inline-block;
        width: 22px;
        height: 22px;
        background:var(--ion-color-primary);	 
        border-radius:0 0 0 100%;
    }
    .cssload-loading .cssload-ani2 i:nth-child(1),
    .cssload-loading .cssload-ani2 i:nth-child(2){
        border-radius: 100% 0 0 0;
        transform-origin:left bottom;
        animation:cssload-loading_cssload-ani2 2s infinite;
    }
    .cssload-loading .cssload-ani2 i:nth-child(1){
        background:rgb(53,77,89);
    }
    .cssload-loading .cssload-ani2 i:nth-child(2){
        backface-visibility: hidden; 
        position:absolute;
        left:0;
    }
    
    
    .cssload-loading .cssload-ani3{
        width:22px;
        font-size:0;
        top:22px;
        animation:cssload-loading_ani5 2s steps(1) 1s infinite;
    }
    .cssload-loading .cssload-ani3 i{
        display:inline-block;
        width: 22px;
        height: 22px;
        background:var(--ion-color-danger-tint);	 
        border-radius:0 0 0 100%;
    }
    .cssload-loading .cssload-ani3 i:nth-child(3){
        background:rgb(53,77,89);
        backface-visibility: hidden; 
    }
    .cssload-loading .cssload-ani3 i:nth-child(2),
    .cssload-loading .cssload-ani3 i:nth-child(3){
        position:absolute;
        left:0;
        transform-origin:right center;
        animation:cssload-loading_cssload-ani3 2s infinite;
    }
    
    .cssload-loading .cssload-ani4{
        width:45px;
        font-size:0;
        top:22px;
        animation:cssload-loading_ani5 2s steps(1) 1.5s infinite;
    }
    .cssload-loading .cssload-ani4 i{
        display:inline-block;
        width: 45px;
        height: 22px;
        background:var(--ion-color-secondary);	 
        border-radius:0 0 22px 22px;
    }
    .cssload-loading .cssload-ani4 i:nth-child(3){
        background:var(--ion-color-danger-tint);
        backface-visibility: hidden; 
    }
    .cssload-loading .cssload-ani4 i:nth-child(2),
    .cssload-loading .cssload-ani4 i:nth-child(3){
        position:absolute;
        left:0;
        transform-origin:center top;
        animation:cssload-loading_cssload-ani4 2s infinite;
    }
    
    
    
    @keyframes cssload-loading_cssload-ani1{
        25%,100%{
            transform:rotateY(180deg);
        }
    }
    
    @keyframes cssload-loading_cssload-ani2{
        25%{
            transform:rotateX(0deg);
        }
        50%,100%{
            transform:rotateX(180deg);
        }
    }
    
    @keyframes cssload-loading_cssload-ani3{
        50%{
            transform:rotateY(0deg);
        }
        75%,100%{
            transform:rotateY(180deg);
        }
    }
    
    @keyframes cssload-loading_cssload-ani4{
        75%{
            transform:rotateX(0deg);
        }
        100%{
            transform:rotateX(180deg);
        }
    }
    
    @keyframes cssload-loading_ani5{
        0%{
            opacity:1;
        }
        25%{
            opacity:0;
        }
    }
}